
.navbar {
    position: fixed;
    left: 0;
    top: 0;
    right: auto;
    bottom: auto;
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-right: 1.4rem;
    padding-left: 1.4rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    will-change: background-color;
    background-color: transparent;
    color:#000;
    font-size: .875rem;
}

nav.menu ul{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content:flex-start;
    grid-column-gap: 1.5rem;
    width:24rem;
    font-weight: 500;

}

.nav-item {
    list-style-type: none;
}

.logo {
    width:12rem;
}

.mail-link {
    display: flex;
    grid-column-gap: .25rem;
    padding: .25rem 0;
    border-bottom: 1px solid #000;
}

.link-icon {
    width:12px;

}

.contact-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    grid-column-gap: 1.5rem;
    width:24rem;
    align-items: center;
    justify-content:flex-end;
}

.instagram {
    width:32px;
    aspect-ratio: 1 / 1;
}


@media screen and (max-width:992px) { 

    nav.menu ul {
        display: flex;
        justify-content: center;
    }

    .contact-info {
        display: none;
    }

    .navbar {
        justify-content: center;
        flex-direction: column-reverse;
        font-size:.7rem;
        gap:.8rem;
        background: #FAF3ED;
        padding:1rem 0;
    }

    .logo {
        width:12rem;
    }

}
