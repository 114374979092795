html {
    line-height: 1.4;
    font-family: 'neue-haas-grotesk-text', sans-serif;
}

  
h1, h2, h3,h4 {
    font-family: 'ivypresto-display', serif;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1.05;
    letter-spacing: -.02em;
}

h5 {
    font-family: 'ivypresto-display', serif;
    font-weight: 300;
    font-style: italic;
    line-height: 1.2;
}

h1 {
    font-size: 6em;
}

h2 {
    font-size: 5em;
}

h3 {
    font-size: 3.375em;
}

h4 {
    font-size: 2.25em;
}
h5 {
    font-size: 1.5em;
}

p {
    font-size:13px;
    padding-top:1.2rem;
}

@media screen and (max-width:991px) { 
    h3 {
        font-size: 4.975em;
    }

    p {
        font-size:12px;
        padding-top:1em;
    }
 }



.container {
   max-width: 90em;
}