* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

img {
  height: auto;
  display: block;
}

a {
  cursor: pointer;
  text-decoration: none;
}

button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-weight: inherit;
  background: none;
}

button, a {
  color: inherit;
}

select, button, textarea {
  font-family: inherit;
}

input, button, textarea, hr {
  border: none;
}

textarea:focus {
  outline: none;
}

ul, li {
  list-style: none;
}

input:focus {
  outline: none;
}

input[type="submit"] {
  cursor: pointer;
}

svg {
  color: inherit;
  fill: inherit;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 640px) {
  button:focus, a:focus, select:focus, option:focus {
    border: none;
    outline: 2px solid #eaeff7;
  }
}

html {
  font-family: neue-haas-grotesk-text, sans-serif;
  line-height: 1.4;
}

h1, h2, h3, h4 {
  text-transform: uppercase;
  letter-spacing: -.02em;
  font-family: ivypresto-display, serif;
  font-weight: 300;
  line-height: 1.05;
}

h5 {
  font-family: ivypresto-display, serif;
  font-style: italic;
  font-weight: 300;
  line-height: 1.2;
}

h1 {
  font-size: 6em;
}

h2 {
  font-size: 5em;
}

h3 {
  font-size: 3.375em;
}

h4 {
  font-size: 2.25em;
}

h5 {
  font-size: 1.5em;
}

p {
  padding-top: 1.2rem;
  font-size: 13px;
}

@media screen and (max-width: 991px) {
  h3 {
    font-size: 4.975em;
  }

  p {
    padding-top: 1em;
    font-size: 12px;
  }
}

.container {
  max-width: 90em;
}

* {
  box-sizing: border-box;
}

html {
  color: #3f3d3c;
  background: #faf3ed;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow-x: hidden;
}

ul {
  list-style-type: none;
}

img {
  width: 100%;
}

section {
  width: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
  position: relative;
  overflow-x: hidden;
}

button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  border: none !important;
  outline: none !important;
}

nav {
  width: 24rem;
  position: relative;
}

.italic {
  font-style: italic;
  font-weight: 100;
}

.gold {
  color: #936306;
}

.bold {
  font-weight: 500;
}

@media screen and (max-width: 1440px) {
  section {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
}

@media screen and (max-width: 991px) {
  section {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

[css-hide-scrollbar]::-webkit-scrollbar {
  display: none;
}

[css-hide-scrollbar] {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.navbar {
  z-index: 999;
  will-change: background-color;
  color: #000;
  background-color: #0000;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  font-size: .875rem;
  display: flex;
  position: fixed;
  inset: 0 auto auto 0;
}

nav.menu ul {
  grid-column-gap: 1.5rem;
  justify-content: flex-start;
  width: 24rem;
  font-weight: 500;
  display: flex;
}

.nav-item {
  list-style-type: none;
}

.logo {
  width: 12rem;
}

.mail-link {
  grid-column-gap: .25rem;
  border-bottom: 1px solid #000;
  padding: .25rem 0;
  display: flex;
}

.link-icon {
  width: 12px;
}

.contact-info {
  grid-column-gap: 1.5rem;
  justify-content: flex-end;
  align-items: center;
  width: 24rem;
  display: flex;
}

.instagram {
  aspect-ratio: 1;
  width: 32px;
}

@media screen and (max-width: 992px) {
  nav.menu ul {
    justify-content: center;
    display: flex;
  }

  .contact-info {
    display: none;
  }

  .navbar {
    background: #faf3ed;
    flex-direction: column-reverse;
    justify-content: center;
    gap: .8rem;
    padding: 1rem 0;
    font-size: .7rem;
  }

  .logo {
    width: 12rem;
  }
}

.home-hero-wrapper {
  background: #faf3ed;
  height: 200vh;
  margin-bottom: -100vh;
}

section.hero {
  background-color: #faf3ed;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  display: flex;
  position: sticky;
  top: 0;
}

.hero-title {
  text-align: center;
  z-index: 1;
  color: #000;
  width: 85%;
  position: absolute;
  top: 32%;
  left: 50%;
  transform: translateX(-50%);
}

.bottom-content {
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  min-height: 25rem;
  padding-bottom: 2rem;
  display: flex;
  position: relative;
}

.image-section {
  aspect-ratio: 3 / 4;
  background: url("hero-image-5.106d8472.jpg") 0 0 / cover;
  border-top-left-radius: 20rem;
  border-top-right-radius: 20rem;
  justify-content: center;
  align-items: flex-end;
  height: 80vh;
  padding-bottom: 4rem;
  display: flex;
  overflow: hidden;
}

.left-content {
  width: 30%;
}

.small-caption {
  max-width: 12rem;
  font-size: .875em;
  line-height: 1.3;
}

@media only screen and (max-width: 992px) {
  .small-caption {
    font-size: 11.0222px;
  }
}

.scroll-icon {
  justify-content: flex-end;
  width: 30%;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .hero-title {
    width: 100%;
    top: 25%;
  }

  .image-section {
    width: 75vw;
    height: auto;
    padding-bottom: 4rem;
  }

  .small-caption {
    text-align: center;
    display: none;
  }

  .bottom-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding-bottom: 10rem;
  }

  .scroll-icon {
    justify-content: center;
    display: flex;
    position: absolute;
    bottom: 17%;
  }
}

.fixed-container-wrapper {
  position: relative;
}

section.fixed-container {
  grid-row-gap: 3rem;
  grid-column-gap: 1.25rem;
  grid-template-columns: repeat(12, [col-start] 1fr);
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: grid;
  position: relative;
}

@media only screen and (max-width: 992px) {
  section.fixed-container {
    grid-row-gap: 2rem;
    grid-column-gap: .875rem;
    grid-template-columns: repeat(6, [col-start] 1fr);
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

section.privat {
  background-color: #f4e8de;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
}

section.privat .heading-fixed {
  color: #936306;
  grid-column: col-start / span 11;
}

section.privat .content-section-paragraph {
  flex-direction: column;
  grid-column: col-start / span 5;
  justify-content: end;
  align-items: end;
  display: flex;
}

section.privat .content-section-image {
  will-change: transform;
  background: url("fixed-image-7.9226a7d9.jpg") 50% 100% / cover;
  grid-column: 7 col-start / span 6;
  padding-top: 67%;
  overflow: hidden;
}

@media only screen and (max-width: 992px) {
  section.privat .heading-fixed {
    grid-column: 1 col-start / span 6;
  }

  section.privat .content-section-paragraph {
    grid-column: 2 col-start / span 5;
  }

  section.privat .content-section-image {
    grid-column: 1 col-start / span 5;
  }
}

section.erhverv {
  text-align: right;
  background-color: #fff9f4;
}

section.erhverv .heading-fixed {
  color: #212020;
  grid-column: 3 col-start / span 11;
}

section.erhverv .content-section-paragraph {
  flex-direction: column;
  grid-column: 8 col-start / span 5;
  justify-content: end;
  align-items: end;
  display: flex;
}

section.erhverv .content-section-image {
  will-change: transform;
  background: url("IMG_3785_redigeret.80dcd124.jpg") 50% 100% / cover;
  grid-column: col-start / span 6;
  padding-top: 67%;
  overflow: hidden;
}

@media only screen and (max-width: 992px) {
  section.erhverv .heading-fixed {
    text-align: left;
    grid-area: 1 / col-start / auto / span 5;
  }

  section.erhverv .content-section-paragraph {
    text-align: left;
    grid-area: 2 / col-start / auto / span 5;
  }

  section.erhverv .content-section-image {
    grid-area: 3 / 2 col-start / auto / span 5;
  }
}

section.merchandising {
  text-align: left;
  background-color: #e3ded9;
}

section.merchandising .heading-fixed {
  color: #494746;
  grid-column: col-start / span 10;
}

section.merchandising .content-section-paragraph {
  flex-direction: column;
  grid-column: col-start / span 5;
  justify-content: end;
  align-items: start;
  display: flex;
}

section.merchandising .content-section-image {
  will-change: transform;
  background: url("samlet.b4c2bc7d.png") 50% 100% / cover;
  grid-column: 7 col-start / span 6;
  padding-top: 67%;
  overflow: hidden;
}

@media only screen and (max-width: 992px) {
  section.merchandising .heading-fixed, section.merchandising .content-section-paragraph {
    grid-column: 2 col-start / span 5;
  }

  section.merchandising .content-section-image {
    grid-column: col-start / span 6;
  }
}

.button-cta {
  grid-column-gap: .25rem;
  color: #000;
  float: left;
  border-bottom: 1px solid #000;
  margin-top: 2rem;
  padding: .25rem 0;
  font-size: .825rem;
  display: flex;
}

.black-cta {
  color: #eee;
  text-align: center;
  background-color: #000;
  border-radius: 50px;
  max-width: 14rem;
  padding: .8rem 1.5rem;
  font-size: .875rem;
  font-weight: 500;
}

@media only screen and (max-width: 992px) {
  .button-cta {
    grid-column-gap: .25rem;
    color: #000;
    float: left;
    border-bottom: 1px solid #000;
    margin-top: 2rem;
    padding: .25rem 0;
    font-size: .825rem;
    display: flex;
  }

  .black-cta {
    text-align: center;
    border-radius: 50px;
    max-width: 14rem;
    padding: .8rem 1.5rem;
    font-size: .875rem;
    font-weight: 500;
  }
}

.big-text {
  min-height: 60rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: relative;
}

.small-cap {
  text-transform: uppercase;
  color: #797979;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  font-size: .75rem;
  font-weight: 300;
  line-height: 1.2;
}

.large-text {
  text-align: center;
  z-index: 9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: calc(75% - 40px);
  max-width: 60rem;
  margin: 10rem auto 2rem;
  display: flex;
  position: relative;
}

.big-text img {
  z-index: 1;
}

.image1 {
  aspect-ratio: 3 / 2;
  max-width: 20rem;
  position: absolute;
  top: 20%;
  right: 4rem;
}

.image2 {
  aspect-ratio: 2 / 3;
  max-width: 12rem;
  position: absolute;
  top: 30%;
  left: 4rem;
}

.image3 {
  aspect-ratio: 3 / 2;
  max-width: 20rem;
  position: absolute;
  bottom: 10%;
  left: 60%;
}

@media only screen and (max-width: 992px) {
  .large-text {
    width: 100%;
  }

  .big-text {
    width: 100%;
    min-height: 35rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .image1 {
    aspect-ratio: 3 / 2;
    max-width: 12rem;
    position: absolute;
    top: 20%;
    right: 1rem;
  }

  .image2 {
    aspect-ratio: 2 / 3;
    max-width: 8rem;
    position: absolute;
    top: 30%;
    left: -2rem;
  }

  .image3 {
    aspect-ratio: 3 / 2;
    max-width: 12rem;
    position: absolute;
    bottom: 15%;
    left: 70%;
  }
}

section.about {
  grid-row-gap: 4rem;
  grid-column-gap: 1.25rem;
  border-top: 1px solid #e4e4e4;
  grid-template-columns: repeat(12, [col-start] 1fr);
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 2rem;
  display: grid;
}

.about-image-section {
  grid-column: 2 col-start / span 5;
}

.about-content-section {
  flex-direction: column;
  grid-column: 8 col-start / span 4;
  gap: 20px;
  display: flex;
}

@media only screen and (max-width: 992px) {
  section.about {
    grid-row-gap: 4rem;
    grid-column-gap: .875rem;
    grid-template-columns: repeat(6, [col-start] 1fr);
    padding-top: 3rem;
    padding-bottom: 1rem;
  }

  .about-image-section {
    grid-area: 2 / 2 col-start / auto / span 4;
  }

  .about-content-section {
    grid-area: 1 / 2 col-start / auto / span 5;
  }
}

.references {
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  margin: 4rem auto;
  padding: 1rem 0 2rem;
  display: flex;
}

.logo-container {
  justify-content: center;
  align-items: center;
  gap: 4rem;
  width: 100%;
  display: flex;
}

.logo-container a {
  align-items: center;
  width: 8rem;
  height: 8rem;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .references {
    margin: 4rem auto;
  }

  .logo-container {
    gap: 2rem;
  }

  .logo-container a {
    width: 5rem;
    height: 5rem;
  }
}

section.instagram-section {
  grid-row-gap: 4rem;
  grid-column-gap: 1.25rem;
  grid-template-columns: repeat(12, [col-start] 1fr);
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 8rem;
  display: grid;
}

.instagram-title {
  grid-area: 1 / col-start / auto / span 5;
}

.instagram-header-content {
  flex-direction: column;
  grid-area: 1 / 9 col-start / auto / span 4;
  display: flex;
}

.insta-feed {
  flex-direction: column;
  grid-area: 2 / col-start / auto / span 12;
  display: flex;
}

@media only screen and (max-width: 992px) {
  section.instagram-section {
    grid-row-gap: 2rem;
    grid-column-gap: .875rem;
    grid-template-columns: repeat(6, [col-start] 1fr);
    padding-top: 1.5rem;
    padding-bottom: 4rem;
  }

  .instagram-title {
    grid-area: 1 / 2 col-start / auto / span 5;
  }

  .instagram-header-content {
    grid-area: 2 / 2 col-start / auto / span 5;
    gap: 1rem;
    margin-top: -2rem;
  }

  .insta-feed {
    grid-area: 3 / col-start / auto / span 6;
  }
}

section.footer-mobile {
  background-color: #f4ece5;
  flex-direction: column;
  gap: 2rem;
  padding-top: 4rem;
  padding-bottom: 1rem;
  display: flex;
}

section.footer-mobile .footer-top-row {
  justify-content: space-between;
  align-items: flex-start;
  gap: 3rem;
  width: 100%;
  display: flex;
}

section.footer-mobile .footer-left-content, section.footer-mobile .footer-right-content {
  flex-direction: column;
  padding-top: 1rem;
  display: flex;
}

section.footer-mobile .footer-title, section.footer-mobile .footer-phone {
  font-size: 1rem;
  font-weight: 500;
}

section.footer-mobile .address-info {
  font-size: 1.5rem;
  line-height: 1.2;
}

section.footer-mobile .footer-text {
  color: #888;
  font-size: .775rem;
}

section.footer-mobile .footer-phone {
  padding-bottom: 1rem;
}

section.footer-mobile .footer-left-content, section.footer-mobile .footer-right-content {
  width: 50%;
}

section.footer-mobile .footer-bottom-row {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

section.footer-mobile .footer-logo {
  width: 100%;
}

section.footer-mobile .copyright, section.footer-mobile .top-text {
  text-align: center;
  padding-top: 0;
  font-size: .65rem;
}

section.footer-mobile .footer-small-bottom {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 2rem;
  display: flex;
}

section.footer-mobile .to-top-link {
  justify-content: flex-end;
  align-items: center;
  gap: .7rem;
  display: flex;
}

.scroll-top-icon {
  max-width: 1.2rem;
}

.eapps-widget-toolbar {
  display: none !important;
}

/*# sourceMappingURL=main.css.map */
