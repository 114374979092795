@import "./reset.css";
@import "./fonts.css";
@import "./base.css";
@import "./nav.css";



.home-hero-wrapper {
    height:200vh;
    margin-bottom:-100vh;
    background: #FAF3ED;
}

section.hero {
    background-color: #FAF3ED;
    height:100vh;
    display: flex;
    justify-content:flex-end;
    align-items: center;
    flex-direction: column;
    position: sticky;
    top: 0px;
}

.hero-title {
    text-align:center;
    width: 85%;
    position:absolute;
    top:32%;
    left:50%;
    transform: translateX(-50%);
    z-index: 1;
    color:#000;
}

.bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width:100%;
    position: relative;
    min-height:25rem;
    padding-bottom: 2rem;
}

.image-section {
    height:80vh;
aspect-ratio: 3 / 4;
    overflow: hidden;
    background: url(/src/assets/hero-image-5.jpg);
    background-size: cover;
    border-top-right-radius: 20rem;
    border-top-left-radius: 20rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
padding-bottom: 4rem;

}

.left-content {
    width:30%;
}

.small-caption {
    max-width:12rem;
    line-height: 1.3;
    font-size:0.875em;
}

@media only screen and (max-width:992px) {
    .small-caption {font-size: 11.022222222222222px;}
   }

.scroll-icon {
    width:30%;
    display: flex;
    justify-content: flex-end;
}


@media only screen and (max-width:992px) {
    section.hero {}
    .hero-title {
        top:25%;
        width: 100%;
    }
    .image-section {
        height:auto;
        width:75vw;
        padding-bottom:4rem;
    }
    .small-caption {
        text-align: center;
        display: none;
    }
    .bottom-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:3rem;
        padding-bottom:10rem;
    }

    .scroll-icon {
        display: flex;
        position: absolute;
        bottom:17%;
        justify-content: center;
    }

}



/* Fixed containers */ 

.fixed-container-wrapper {
    position:relative;
}

section.fixed-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-row-gap: 3rem;
    grid-column-gap: 1.25rem;
    position:relative;

}
@media only screen and (max-width:992px) {
    section.fixed-container {
        grid-template-columns: repeat(6, [col-start] 1fr);
        grid-row-gap: 2rem;
        grid-column-gap: 0.875rem;
        padding-top:3rem;
        padding-bottom:3rem;
    }
}

section.privat {
    background-color: #F4E8DE;
    border-top-right-radius: 3rem;
    border-top-left-radius: 3rem;
}



section.privat .heading-fixed {
    grid-column: col-start / span 11;
    color:#936306;
}

section.privat .content-section-paragraph {
    grid-column: col-start / span 5;
    display: flex;
    align-items: end;
    flex-direction: column;
    justify-content: end;
}

section.privat .content-section-image {
    grid-column: col-start 7 / span 6;
    background: url("/src/assets/fixed-image-7.jpg");
    padding-top:67%;
    background-size: cover;
    will-change: transform;
    overflow: hidden;
    background-position: 50% 100%;
}

@media only screen and (max-width:992px) {
    section.privat .heading-fixed {
        grid-column: col-start 1 / span 6;
    }
    section.privat .content-section-paragraph {
        grid-column: col-start 2 / span 5;
    }
    section.privat .content-section-image {
        grid-column: col-start 1 / span 5;
    }
}


section.erhverv {
    background-color: #FFF9F4;
    text-align: right;

}

section.erhverv .heading-fixed {
    grid-column: col-start 3 / span 11;
    color:#212020;
}

section.erhverv .content-section-paragraph {
    grid-column: col-start 8 / span 5;
    display: flex;
    align-items: end;
    flex-direction: column;
    justify-content: end;
}

section.erhverv .content-section-image {
    grid-column: col-start / span 6;
    background: url("/src/assets/IMG_3785_redigeret.jpg");
    padding-top:67%;
    background-size: cover;
    will-change: transform;
    overflow: hidden;
    background-position: 50% 100%;
}


@media only screen and (max-width:992px) {
    section.erhverv .heading-fixed {
        grid-column: col-start  / span 5;
        text-align: left;
        grid-row:1;
    }
    section.erhverv .content-section-paragraph {
        grid-column: col-start / span 5;
        grid-row:2;
        text-align: left;
    }
    section.erhverv .content-section-image {
        grid-column: col-start 2/ span 5;
        grid-row:3;
    }
}


section.merchandising {
    background-color: #E3DED9;
    text-align: left;

}

section.merchandising .heading-fixed {
    grid-column: col-start / span 10;
    color:#494746;
}

section.merchandising .content-section-paragraph {
    grid-column: col-start / span 5;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: end;
}

section.merchandising .content-section-image {
    grid-column: col-start 7 / span 6;
    background: url("/src/assets/samlet.png");
    padding-top:67%;
    background-size: cover;
    will-change: transform;
    overflow: hidden;
    background-position: 50% 100%;
}

@media only screen and (max-width:992px) {
    section.merchandising .heading-fixed {
        grid-column: col-start  2/ span 5;
    }
    section.merchandising .content-section-paragraph {
        grid-column: col-start  2/ span 5;
    }
    section.merchandising .content-section-image {
        grid-column: col-start / span 6;
    }
}

/* Buttons */ 

.button-cta {
    display: flex;
    font-size:0.825rem;
    grid-column-gap: .25rem;
    padding: .25rem 0;
    border-bottom: 1px solid #000;
    margin-top:2rem;
    color:#000;
    float:left;
}

.black-cta {
    background-color: #000;
    color:#eee;
    border-radius:50px;
    padding: .8rem 1.5rem;
    font-weight: 500;
    font-size: .875rem;
    max-width:14rem;
    text-align: center;
}

@media only screen and (max-width:992px) { 
    .button-cta {
        display: flex;
        font-size:0.825rem;
        grid-column-gap: .25rem;
        padding: .25rem 0;
        border-bottom: 1px solid #000;
        margin-top:2rem;
        color:#000;
        float:left;
    }
    
    .black-cta {
        border-radius:50px;
        padding: .8rem 1.5rem;
        font-weight: 500;
        font-size: .875rem;
        max-width:14rem;
        text-align: center;
    }
}


.big-text {
    padding-top: 4rem;
    padding-bottom: 4rem;
    min-height:60rem;
    position: relative;
}

.small-cap {
    font-weight:300;
    line-height: 1.2;
    text-transform: uppercase;
    color:#797979;
    font-size: 0.75rem;
    text-align:center;
    margin:0 auto;
    width:100%;
}

.large-text {
justify-content: center;
align-items: center;
width:calc(75% - 40px);
margin: 10rem auto 2rem;
text-align: center;
max-width:60rem;
z-index:9;
position: relative;
display: flex;
gap:30px;
flex-direction: column;
}

.large-centered-text {

}

.big-text img {
    z-index:1;
}

.image1 {
    position: absolute;
    top: 20%;
    right:4rem;
    aspect-ratio: 3 / 2;
    max-width: 20rem;
}
.image2 {
    position: absolute;
    top: 30%;
    left:4rem;
    aspect-ratio: 2 / 3;
    max-width: 12rem;
}
.image3 {
    position: absolute;
    bottom: 10%;
    left:60%;
    aspect-ratio: 3 / 2;
    max-width: 20rem;
}

@media only screen and (max-width:992px) { 
    
    .large-text {
        width:100%;
    }
    .big-text {
        min-height:35rem;
        width:100%;
        padding-top:3rem;
        padding-bottom:3rem;
    }
    .image1 {
        position: absolute;
        top: 20%;
        right:1rem;
        aspect-ratio: 3 / 2;
        max-width: 12rem;
    }
    .image2 {
        position: absolute;
        top: 30%;
        left:-2rem;
        aspect-ratio: 2 / 3;
        max-width: 8rem;
    }
    .image3 {
        position: absolute;
        bottom: 15%;
        left:70%;
        aspect-ratio: 3 / 2;
        max-width: 12rem;
    }
 }


/* About section */ 

section.about {
    padding-top: 2.5rem;
    padding-bottom: 2rem;
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-row-gap: 4rem;
    grid-column-gap: 1.25rem;
    align-items: center;
    border-top: 1px solid #E4E4E4;
}


.about-image-section {
    grid-column: col-start 2 / span 5;
}

.about-content-section {
    grid-column: col-start 8 / span 4;
    display: flex;
    flex-direction: column;
    gap:20px;
}

@media only screen and (max-width:992px) {

    section.about {
        grid-template-columns: repeat(6, [col-start] 1fr);
        grid-row-gap: 4rem;
        grid-column-gap: 0.875rem;
        padding-top:3rem;
        padding-bottom:1rem;
    }

    .about-image-section {
        grid-column: col-start 2 / span 4;
        grid-row: 2;
    }
    
    .about-content-section {
        grid-column: col-start 2 / span 5;
        grid-row: 1;
    }
    
    
}

/* Logo section */

.references {
    width:100%;
    margin: 4rem auto;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    justify-content: center;
    border-top: 1px solid #E4E4E4;
    border-bottom: 1px solid #E4E4E4;
    padding: 1rem 0 2rem;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap:4rem;
    width:100%;
}

.logo-container a {
    width:8rem;
    height:8rem;
    display: flex;
    align-items: center;
}

@media only screen and (max-width:992px) {

    .references {
        margin: 4rem auto;
    }
    .logo-container {
        gap:2rem;
    }
    .logo-container a {
        width:5rem;
        height:5rem;
    }
 }

section.instagram-section {
    padding-top: 2.5rem;
    padding-bottom: 8rem;
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-row-gap: 4rem;
    grid-column-gap: 1.25rem;
    align-items: center;
}

.instagram-title {
    grid-column: col-start / span 5;
    grid-row: 1;
}

.instagram-header-content {
    grid-column: col-start 9 / span 4;
    grid-row: 1;
    display: flex;
    flex-direction: column;
}

.insta-feed {
    grid-column: col-start  / span 12;
    grid-row: 2;
    display: flex;
    flex-direction: column;
}


@media only screen and (max-width:992px) {
    
    section.instagram-section {
        grid-template-columns: repeat(6, [col-start] 1fr);
        grid-row-gap: 2rem;
        grid-column-gap: 0.875rem;
        padding-top: 1.5rem;
        padding-bottom: 4rem;
    }
    
    .instagram-title {
        grid-column: col-start 2 / span 5;
        grid-row:1
    }
    
    .instagram-header-content {
        grid-column: col-start 2 / span 5;
        grid-row:2;
gap:1rem;
margin-top:-2rem;
    }

    .insta-feed {
        grid-column: col-start  / span 6;
        grid-row:3;
    }
    
}

/* Footer */ 


section.footer-mobile {
    padding-top:4rem;
    padding-bottom: 1rem;
    background-color: #F4ECE5;
    display: flex;
    flex-direction: column;
    gap:2rem;
}

section.footer-mobile .footer-top-row {
    display: flex;
    justify-content: space-between;
    align-items:flex-start;
    width:100%;
    gap:3rem;
}

section.footer-mobile .footer-left-content, section.footer-mobile .footer-right-content {
    display: flex;
    flex-direction: column;
    padding-top:1rem;
}

section.footer-mobile .footer-title, section.footer-mobile .footer-phone {
    font-size: 1rem;
    font-weight: 500;
}

section.footer-mobile .address-info {
    font-size: 1.5rem;
    line-height: 1.2;
}

section.footer-mobile .footer-text {
    color: #888888;
    font-size:0.775rem;
}

section.footer-mobile .footer-phone {
    padding-bottom: 1rem;
}

section.footer-mobile .footer-left-content, section.footer-mobile .footer-right-content {
width:50%;
}


section.footer-mobile .footer-bottom-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    width:100%;
}

section.footer-mobile .footer-logo {
    width: 100%;
}



section.footer-mobile .copyright, section.footer-mobile .top-text {
    font-size: 0.65rem;
    text-align: center;
    padding-top:0;
}

section.footer-mobile .footer-small-bottom {
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top:2rem;
}

section.footer-mobile .to-top-link {
    display: flex;
    align-items: center;
    gap:0.7rem;
    justify-content: flex-end;
}

.scroll-top-icon {
    max-width:1.2rem;
}


.eapps-widget-toolbar {
    display:none !important;
}